import { defineComponent, computed, onMounted, ref, watch, getCurrentInstance } from '@vue/composition-api';
import { cloneDeep, isEqual } from 'lodash';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import FormVerticalRadio from '@/shared/components/form/FormVerticalRadio.vue';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import SpaceBookingRulesForm from '@/pages/vendor/space/booking-rules/forms/SpaceBookingRulesForm';
import FormIncrementedInput from '@/shared/components/form/FormIncrementedInput.vue';
import toJSON from '@/api/base/toJSON';
import getSpace from '@/api/space/getById';
import useAsyncPage from '@/shared/composables/useAsyncPage';
import { UserEventPermission } from '@/generated-types/graphql.types';
export default defineComponent({
    components: {
        FormIncrementedInput,
        FormVerticalRadio,
        PageLoadingBar
    },
    props: {
        revert: {
            type: Boolean,
            default: false
        },
        onSave: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const form = ref(new SpaceBookingRulesForm());
        const formLoaded = ref({});
        const instance = getCurrentInstance();
        const textValueWhenZero = ref(!form.value.bookingsNumber);
        const handleDecrementInput = val => {
            if (!val)
                textValueWhenZero.value = true;
        };
        const handleInputBlur = () => {
            setTimeout(() => (textValueWhenZero.value = !form.value.bookingsNumber), 200);
        };
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const hasSpaceBookingTypeSelectorPermission = computed(() => user.value?.event_permissions[UserEventPermission.AllowedToSetSpaceBookingTypes] || false);
        const spaceBookingRules = computed(() => globalThis.$store.getters['$_vendor/spaceBookingRules']);
        const checkErrors = () => {
            return [];
        };
        const availabilityOptions = computed(() => {
            return [
                {
                    label: `0 ${root.$tc('common.hour', 0)}`,
                    value: 0
                },
                {
                    label: `1 ${root.$tc('common.hour', 1)}`,
                    value: 1
                },
                {
                    label: `2 ${root.$tc('common.hour', 2)}`,
                    value: 2
                },
                {
                    label: `4 ${root.$tc('common.hour', 4)}`,
                    value: 4
                },
                {
                    label: `8 ${root.$tc('common.hour', 8)}`,
                    value: 8
                },
                {
                    label: `12 ${root.$tc('common.hour', 12)}`,
                    value: 12
                },
                {
                    label: `24 ${root.$tc('common.hour', 24)}`,
                    value: 24
                },
                {
                    label: `2 ${root.$tc('common.day', 2)}`,
                    value: 48
                },
                {
                    label: `3 ${root.$tc('common.day', 3)}`,
                    value: 72
                },
                {
                    label: `4 ${root.$tc('common.day', 4)}`,
                    value: 96
                },
                {
                    label: `5 ${root.$tc('common.day', 5)}`,
                    value: 120
                },
                {
                    label: `1 ${root.$tc('common.week_plural', 1)}`,
                    value: 168
                },
                {
                    label: `2 ${root.$tc('common.week_plural', 2)}`,
                    value: 336
                }
            ];
        });
        const manualBookingOptions = computed(() => {
            return [
                {
                    label: `24 ${root.$tc('common.hour', 24)} (${root.$t('common.advised')})`,
                    value: 24
                },
                {
                    label: `48 ${root.$tc('common.hour', 48)}`,
                    value: 48
                }
            ];
        });
        const refundOptions = computed(() => {
            return [
                {
                    label: `1 ${root.$tc('common.hour', 1)}`,
                    value: 1
                },
                {
                    label: `12 ${root.$tc('common.hour', 12)}`,
                    value: 12
                },
                {
                    label: `24 ${root.$tc('common.hour', 24)}`,
                    value: 24
                },
                {
                    label: `48 ${root.$tc('common.hour', 48)}`,
                    value: 48
                },
                {
                    label: `72 ${root.$tc('common.hour', 72)}`,
                    value: 72
                },
                {
                    label: `1 ${root.$tc('common.week_plural', 1)}`,
                    value: 168
                },
                {
                    label: `2 ${root.$tc('common.week_plural', 2)}`,
                    value: 336
                },
                {
                    label: `1 ${root.$tc('common.month_plural', 1)}`,
                    value: 720
                },
                {
                    label: `2 ${root.$tc('common.month_plural', 2)}`,
                    value: 1440
                },
                {
                    label: `3 ${root.$tc('common.month_plural', 3)}`,
                    value: 2160
                },
                {
                    label: `4 ${root.$tc('common.month_plural', 4)}`,
                    value: 2880
                }
            ];
        });
        watch(() => spaceBookingRules.value, value => {
            if (value) {
                form.value.loadFromModel(spaceBookingRules.value);
            }
        }, { immediate: true });
        watch(() => props.revert, () => {
            if (props.revert) {
                form.value = cloneDeep(formLoaded.value);
            }
        }, { immediate: true });
        watch(() => props.onSave, () => {
            if (props.onSave) {
                formLoaded.value = cloneDeep(form.value);
            }
        }, { immediate: true });
        watch(() => form.value, () => {
            if (formLoaded.value && !isEqual(formLoaded.value, form.value)) {
                emit('updateSpaceDetails', {
                    data: {
                        cancellationTh: form.value.cancellationTime,
                        bookBeforeMinTh: form.value.availableTime,
                        spaceBookingType: +form.value.selectedBookingTypeOption
                            ? 'MANUAL'
                            : 'DIRECT',
                        hostApprovalTh: form.value.decisionTime,
                        bookingLimit: form.value.bookingsNumber,
                        isAfterpayAllowed: form.value.selectedPaymentOption !== 1,
                        isPaymentOptionsSelectable: form.value.selectedPaymentOption === 2
                    }
                });
            }
            else {
                form.value.manual = form.value.selectedBookingTypeOption === 1;
                form.value.isAfterpayAllowed = form.value.selectedPaymentOption !== 1;
                form.value.isPaymentOptionsSelectable =
                    form.value.selectedPaymentOption === 2;
                emit('noChanges');
            }
        }, { deep: true, immediate: true });
        const mainAction = async () => {
            form.value.validate(instance);
            globalThis.$store.commit('$_vendor/SET_SPACE_BOOKING_RULES', form.value);
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.FACILITIES,
                params: { venueId: root.$route.params.venueId }
            });
        };
        watch(() => form.value, () => {
            if (!spaceBookingRules.value)
                return;
            globalThis.$store.commit('$_vendor/SET_SPACE_BOOKING_RULES', form.value);
        }, { deep: true, immediate: true });
        const { dataLoaded, callAPIAction } = useAsyncPage();
        onMounted(async () => {
            const { spaceId } = root.$route.params;
            await callAPIAction('$_vendor/loadSpaceFromStorage');
            if (spaceId) {
                const space = await toJSON(getSpace(spaceId));
                form.value.cancellationTime = space.cancellation_th;
                form.value.availableTime = space.book_before_min_th;
                form.value.selectedBookingTypeOption =
                    space.space_booking_type === 'MANUAL' ? 1 : 0;
                form.value.decisionTime = space.host_approval_th;
                form.value.bookingsNumber = space.booking_limit;
                form.value.selectedPaymentOption = space.is_afterpay_allowed
                    ? space.is_payment_options_selectable
                        ? 2
                        : 0
                    : 1;
                form.value.isAfterpayAllowed = space.is_afterpay_allowed;
                form.value.isPaymentOptionsSelectable =
                    space.is_payment_options_selectable;
                form.value.manual = space.space_booking_type === 'MANUAL';
                formLoaded.value = cloneDeep(form.value);
            }
        });
        return {
            availabilityOptions,
            dataLoaded,
            form,
            hasSpaceBookingTypeSelectorPermission,
            manualBookingOptions,
            refundOptions,
            spaceBookingRules,
            textValueWhenZero,
            checkErrors,
            handleDecrementInput,
            handleInputBlur,
            mainAction
        };
    }
});
