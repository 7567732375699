import BaseForm from '@/shared/models/BaseForm';
class SpaceBookingRulesModel extends BaseForm {
    availableTime;
    bookingsNumber;
    cancellationTime;
    decisionTime;
    id;
    isAfterpayAllowed;
    isPaymentOptionsSelectable;
    manual;
    selectedBookingTypeOption;
    selectedPaymentOption;
    constructor() {
        super();
        this.id = '';
        this.manual = false;
        this.selectedBookingTypeOption = 0;
        this.cancellationTime = 24;
        this.availableTime = 24;
        this.decisionTime = 24;
        this.bookingsNumber = null;
        this.selectedPaymentOption = 1;
        this.isAfterpayAllowed = false;
        this.isPaymentOptionsSelectable = false;
    }
    apiAttributes() {
        return {
            id: 'id',
            cancellationTime: 'cancellation-time',
            availableTime: 'available-time',
            decisionTime: 'decision-time',
            bookingsNumber: 'booking_limit',
            isAfterpayAllowed: 'is_afterpay_allowed',
            isPaymentOptionsSelectable: 'is_payment_options_selectable'
        };
    }
}
export default SpaceBookingRulesModel;
